/* 
--------------------------
Opinionated Jawbone resets in addition to normalize.css 4.0
--------------------------
*/

body, nav,
figure, img,
picture, video,
table, td, th, tr,
p, header,
blockquote, q,
ul, ol, li,
h1, h2, h3, h4, h5, h6 {
    margin:  0;
    padding: 0;
}

audio,
canvas,
img,
figure,
picture,
svg,
video {
    vertical-align: middle;
}

picture, video, img {
    position: relative;
    max-width: 100%;
}

video {
    width: 100%;
    height: auto;
}

cite,
picture,
figcaption,
blockquote {
    display: block;
}

caption:not(:focus):not(:active) {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    -webkit-clip-path: inset(100%);
            clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

abbr[title],
dfn[title] {
    cursor: help;
}

a, b, strong, th, h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}
/*
--------------------------
Fonts
--------------------------
*/

/* @import url('https://fonts.googleapis.com/css?family=Raleway'); */

